.cohortTable {}

.cohortTableCell {
  padding: 0 !important;
}

.coloredTableCell {
  padding: 11px 16px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}

.moreThan90 {
  background-color: #258D4E;
}

.moreThan80 {
  background-color: #60B362;
}

.moreThan70 {
  background-color: #9ED16E;
}

.moreThan60 {
  background-color: #FFFEBF;
  color: #333;
}

.moreThan50 {
  background-color: #FEDB8C;
  color: #333;
}

.moreThan40 {
  background-color: #FBA466;
}

.moreThan30 {
  background-color: #EF674B;
}

.moreThan20 {
  background-color: #e65639;
}

.moreThan0 {
  background-color: #CE2F2D;
}
